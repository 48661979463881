import { get } from "lodash"
import apiRequestBase from "./apiRequestBase"

const environmentSolrCore = `${process.env.GATSBY_SOLR_CORE}` || null
const isDdev = `${process.env.IS_DDEV_SOLR}` || null

/**
 * @typedef {import("./solr/SolrQueryBuilder").SolrQueryBuilder} SolrQueryBuilder
 */

/**
 * @typedef {(query: SolrQueryBuilder) => Promise<any>} RunQuery
 */

/**
 * Solr API client.
 * @extends {apiRequestBase}
 * @property {string} basePath
 * @property {string} core
 * @property {RunQuery} runQuery
 */
export class SolrApi extends apiRequestBase {

  /**
   * Solr Api request instance constructor.
   * @param host Solr host.
   * @param port Solr port.
   * @param path Solr path.
   * @param core= Solr core.
   * @param useSecure= Determine if conneection should use secure protocol.
   */
  constructor({ host, port, path, core, useSecure = true, authToken = null }) {
    const defaultPath = "/solr"
    const finalPath = path || defaultPath
    const protocol = useSecure ? "https" : "http"
    const baseUrl = `${protocol}://${host}:${port}`
    super({ baseUrl, authToken, csrfToken: null })
    this.basePath = finalPath
    this.core = environmentSolrCore || core
    if (this.core == "local" && isDdev === "yes") {
      const finalPath = '/solr/goway_solr/select'
      this.baseUrl = `https://gw-solr.ddev.site`
      this.core = ''
      this.basePath = finalPath
    }
    return this
  }

  /**
   * Process a Solr API endpoint request.
   * @param {string} endpoint An API endpoint path
   * @param {any} data Payload data
   * @param {Object<string, string>=} customHeaders
   * @param {string} method
   * @return {Promise<Jsonapi>}
   */
  doRequest = async (endpoint, data = null, customHeaders, method = "") => {
    const response = this.doApiRequest({
      endpoint,
      data,
      customHeaders,
      method,
    })

    const errorCode = get(response, "errorCode", null)
    if (errorCode === 403) {
      console.log(
        "solr_api_request_failed",
        "error",
        "Solr API request was forbidden for current user."
      )

      return null
    }

    return response
  }

  /**
   * Run a Solr query.
   *
   * @param {SolrQueryBuilder} query
   */
  runQuery = async (query) => {
    const pathArray = [this.basePath, this.core]
    const endpoint = pathArray.filter((e) => e).join("/")
    const data = query.build() || ""
    const queryString = data ? data + "&wt=json" : "wt=json"

    const customHeaders = {
      accept: "application/json",
    }

    if (this.authorization) {
      customHeaders["authorization"] = this.authorization
    }

    const response = await this.doRequest(`${endpoint}?${queryString}`, null, customHeaders, "GET")
    if (!response) {
      return null
    }

    return response
  }
}
