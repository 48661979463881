import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hook to consume promo index data and transform it into a UI component format.
 */
const usePromoSolrData = () => {
  const { promoDataFilterAndTransform } = useDataObjectTransform()
  const getPromos = async (country, campaignName) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "promo_index",
        its_country: country,
      })
      .fq([
        {
          field: `(ss_campaign_name:${campaignName} OR ss_campaign_name:__empty)`,
        },
      ])
      .sort({
        ss_campaign_name_str: "asc",
      })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return promoDataFilterAndTransform(solrRecords)
  }

  return {
    getPromos,
  }
}

export default usePromoSolrData
