type SearchDataValue = {
  label: string
  value: string
  experience?: string
}

type SearchData = {
  country: SearchDataValue
  destination: SearchDataValue
  location: SearchDataValue
  budget: string[]
  duration: string[]
  experiences?: SearchDataValue[]
}

const getLabelFromString = (value: string) => {
  const result: string[] = []
  value.split(",").forEach((item) => result.push(item.split(":")[0]))
  return result.join()
}

const getIdFromString = (value: string) => {
  const result: string[] = []
  value?.split(",").forEach((item) => result.push(item?.split(":")[1]))
  const id = result.join()
  return id
}

type QueryRange = {
  min: number
  max: number
}

const getRangeFromString = (value: string = ""): QueryRange => {
  const parts = value.split("-")

  return {
    min: parseInt(parts?.[0]) || 0,
    max: parseInt(parts?.[1]) || 0,
  }
}

const formatSearchData = (data: SearchData) => {
  const country: string = data?.country?.value
  const destination: string = data?.destination?.value
  const tripStyles: string[] = []
  const tripTypes: string[] = []
  const duration: string[] = [data?.duration?.[0], data?.duration?.[1]]
  const budget: string[] = [data?.budget?.[0], data?.budget?.[1]]

  data?.experiences?.forEach((item: any) => {
    if (item?.experience === "Trip Styles") tripStyles.push(item?.value)
    else tripTypes.push(item?.value)
  })

  return {
    country,
    destination,
    tripStyles: tripStyles.join(","),
    tripTypes: tripTypes.join(","),
    duration: {
      min: duration[0],
      max: duration[1],
    },
    budget: {
      min: budget[0],
      max: budget[1],
    },
  }
}

const formatSearchDataByCountry = (data: SearchData) => {
  const location: string = data?.location?.value
  const country: string = data?.country?.value
  const tripStyles: string[] = []
  const tripTypes: string[] = []
  const duration: string[] = [data?.duration?.[0], data?.duration?.[1]]
  const budget: string[] = [data?.budget?.[0], data?.budget?.[1]]

  data?.experiences?.forEach((item: any) => {
    if (item?.experience === "Trip Styles") tripStyles.push(item?.value)
    else tripTypes.push(item?.value)
  })

  return {
    location,
    country,
    tripStyles: tripStyles.join(","),
    tripTypes: tripTypes.join(","),
    duration: {
      min: duration[0],
      max: duration[1],
    },
    budget: {
      min: budget[0],
      max: budget[1],
    },
  }
}

const setFormFields = (params: any) => {
  const result: any = {}
  const experiences: any = []
  if (params?.location) {
    result.location = {
      label: getLabelFromString(params?.location),
      value: getIdFromString(params?.location),
    }
  }

  if (params?.country) {
    result.country = {
      label: getLabelFromString(params?.country),
      value: getIdFromString(params?.country),
    }
  }

  if (params?.destination) {
    result.destination = {
      label: getLabelFromString(params?.destination),
      value: getIdFromString(params?.destination),
    }
  }

  if (params?.tripStyles) {
    const array = params?.tripStyles.split(",")
    array.forEach((item: string) => {
      experiences.push({ label: getLabelFromString(item), experience: "Trip Styles" })
    })
  }

  if (params?.tripTypes) {
    const array = params?.tripTypes.split(",")
    array.forEach((item: string) => {
      experiences.push({ label: getLabelFromString(item), experience: "Trip Types" })
    })
  }

  if (params?.duration) {
    const range = getRangeFromString(params?.duration)
    result.duration = {
      label: getLabelFromString(params?.duration),
      value: [range.min, range.max],
    }
  }

  if (params?.budget) {
    const range = getRangeFromString(params?.budget)
    result.budget = {
      label: getLabelFromString(params?.budget),
      value: [range.min, range.max],
    }
  }

  return {
    ...result,
    experiences,
  }
}

const getSortOption = (param: string) => {
  if (param) {
    const sortLabel = param.split("-")[0]
    const sortType = param.split("-")[1]
    const isPriceSort = sortLabel.includes("price")
    return isPriceSort ? `price-${sortType}` : `its_duration-${sortType}`
  }
  return null
}

export {
  getLabelFromString,
  getIdFromString,
  getRangeFromString,
  formatSearchData,
  formatSearchDataByCountry,
  setFormFields,
  getSortOption,
}
