import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hook to consume articles index data and transform it into a UI component format.
 */
const useArticlesSolrData = () => {
  const { articleTeaserTransform, articleOverrideTransform } = useDataObjectTransform()
  const { authorArticlesCollectionDataFilterAndTransform, articleTypeDataTransform } =
    useDataObjectTransform()

  /**
   * Get articles for a given country nid.
   * @param {object} component The override nids.
   * @param {number|null} country The country nid.
   * @param {number|null} destination The destination nid.
   * return {array} Articles The articles.
   */
  const getArticles = async (component = {}, country = null, destination = null) => {
    let override = []
    const mappedOverrideList =
      component?.article_list_override && component?.article_list_override?.length
        ? articleOverrideTransform(component?.article_list_override)
        : []
    const isExpired = component?.expire_date ? Date.parse(component.expire_date) < Date.now() : true

    if (
      !isExpired &&
      component?.article_list_override &&
      component?.article_list_override?.length >= 3
    ) {
      return mappedOverrideList
    }
    if (component?.article_list_override && component?.article_list_override?.length < 3) {
      override = component.article_list_override
        ? component.article_list_override.map((item) => item.external_id)
        : []
    }
    if (isExpired) {
      override = []
    }
    const solrQuery = new SolrQueryBuilder(queryConfig)
    const query = {
      index_id: "article_index",
      bs_status: "true",
      ss_type: "article",
    }

    if (country) {
      query.itm_country_nid = country
    }

    if (destination) {
      query.itm_destinations_nid = destination
    }

    solrQuery
      .q(query)
      .sort({ score: "desc", ds_created: "desc" })
      .bq({
        ...(country ? { itm_country_nid: `${country}^200` } : {}),
        ...(destination ? { itm_destinations_nid: `${destination}^100` } : {}),
      })
      .defType("edismax")
    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const transform = articleTeaserTransform(solrRecords)
    const filter = transform
      .filter((article) => {
        return !override.includes(article.nid[0])
      })
      .slice(0, 3 - override.length)
    return [
      ...(!isExpired && component?.article_list_override ? mappedOverrideList : []),
      ...filter,
    ]
  }

  /**
   * @param {number} authorId The author nid.
   * @param {[key: string]: string} authorfLink The author info.
   */
  const getAuthorArticles = async (authorId, authorfLink, startIndex = 0) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        its_authored_by_nid: authorId,
      })
      .sort({ id: "asc" })
      .rows(9)
      .start(startIndex)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return {
      count: response?.response?.numFound,
      items: authorArticlesCollectionDataFilterAndTransform(solrRecords, authorfLink),
    }
  }

  /**
   * @param {number} id Category nid.
   * @param {[key: string]: string} articleCategories Article categories.
   * @param {number} index Defines amount of variant cards.
   */

  const getArticlesByCategory = async (id, articleCategories, index) => {
    const cardVariant = !Number.isInteger(index / 3)
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        its_article_category_nid: id,
      })
      .sort({ ds_created: "desc" })
      .rows(cardVariant ? 4 : 3)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const findArticleCategory = articleCategories.find(
      (category) => category.external_id === solrRecords[0]?.its_article_category_nid[0]
    )
    if (solrRecords.length > 0) {
      const articleCategory = {
        title: findArticleCategory.title,
        link: { title: "See All", uri: findArticleCategory.self_link.uri },
        variant: cardVariant ? "fourCards" : "threeCards",
        backgroundColor: cardVariant ? "white" : "orange",
        items: authorArticlesCollectionDataFilterAndTransform(solrRecords, {}),
      }
      return articleCategory
    }
  }

  /**
   * @param {number} external_id Article type id.
   * @param {number} startIndex Page number.
   */

  const getArticlesByType = async (external_id, startIndex = 0) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        its_article_type_nid: external_id,
      })
      .sort({ its_nid: "desc" })
      .rows(9)
      .start(startIndex)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return {
      count: response?.response?.numFound,
      items: articleTypeDataTransform(solrRecords),
    }
  }

  const getTrendingArticles = async () => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        ss_article_type_title: "Trending",
      })
      .sort({ ds_created: "desc" })
      .rows(3)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return authorArticlesCollectionDataFilterAndTransform(solrRecords, {})
  }

  const getArticlesByNid = async (ids, amount) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        its_nid: `(${ids})`,
      })
      .sort({ id: "asc" })
      .rows(amount)
    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return authorArticlesCollectionDataFilterAndTransform(solrRecords, {}, true)
  }

  return {
    getArticles,
    getAuthorArticles,
    getArticlesByCategory,
    getArticlesByType,
    getTrendingArticles,
    getArticlesByNid,
  }
}

export default useArticlesSolrData
